import { Route, Switch } from "react-router-dom";

import screens from "../screens";

const Routes = () => (
  <Switch>
    <Route path={RoutePaths["BLOGS_CREATE"]} component={screens.BlogsCreate} />
    <Route path={RoutePaths["BLOGS_EDIT"]} component={screens.BlogsEdit} />
    <Route path={RoutePaths["BLOGS_ACTIVE"]} component={screens.Blogs} />
    <Route path={RoutePaths["BLOGS_DEACTIVE"]} component={screens.Blogs} />
    <Route path={RoutePaths["BLOGS_DRAFTS"]} component={screens.Blogs} />
    <Route path={RoutePaths["BLOGS_CATEGORIES"]} component={screens.Blogs} />
    <Route path={RoutePaths["BLOGS_TAGS"]} component={screens.Blogs} />
    <Route path={RoutePaths["BLOGS"]} component={screens.Blogs} />
    <Route path={RoutePaths["HOME"]} exact component={screens.Home} />
  </Switch>
);

export const RoutePaths = {
  HOME: "/",
  BLOGS: "/blogs",
  BLOGS_CREATE: "/blogs/create",
  BLOGS_EDIT: "/blogs/edit/:slug",
  BLOGS_ACTIVE: "/blogs/active",
  BLOGS_DEACTIVE: "/blogs/deactive",
  BLOGS_DRAFTS: "/blogs/drafts",
  BLOGS_CATEGORIES: "/blogs/categories",
  BLOGS_TAGS: "/blogs/tags",
};

export function findRouteFromPathname(pathname) {
  var route = undefined;

  Object.keys(RoutePaths).find((key) => {
    if (RoutePaths[key] === pathname) {
      route = {
        name: key,
        path: pathname,
      };
      return true;
    }
  });

  if (!route) {
    route = {
      name: "UNKNOWN PATH",
      path: pathname,
    };
  }
  return route;
}

export default Routes;
