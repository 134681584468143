import { Suspense } from "react";
import Routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import Loader from "./components/loader";
import Preloader from "./components/preloader";
export default function App() {
  return (
    <Suspense fallback={Preloader}>
      <Router>
        <Routes />
      </Router>
    </Suspense>
  );
}
