import { lazy } from "react";

const Home = lazy(() => import("./home"));
const Blogs = lazy(() => import("./blogs"));
const BlogsCreate = lazy(() => import("./blogs-create"));
const BlogsEdit = lazy(() => import("./blogs-edit"));

export default {
  Home,
  Blogs,
  BlogsCreate,
  BlogsEdit,
};
