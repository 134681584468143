import classNames from "classnames";
import { useState } from "react";
import "./desktop.scss";

export default function Preloader({ openState }) {
  const [isOpen, setOpen] = useState(openState || true);

  return (
    <div
      className={
        "x-preloader-component flex-column justify-content-center align-items-center"
      }
      style={{ height: isOpen ? "100vh" : 0 }}
    >
      <img
        className="animation__shake"
        src="/admin-lte/dist/img/AdminLTELogo.png"
        alt="AdminLTELogo"
        height={60}
        width={60}
      />
    </div>
  );
}
